import * as React from "react";
import HcpLayout from "../components/hcp/layout";
import { Row, Col } from "react-grid-system";
import { Seo } from "../components/seo";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../sass/_orphan.scss";
import "./inspiration-builder.scss";
import pageIcon from "../images/inspiration-builder/inspiration-gen-icon.svg";
import sliderDesktopImg1 from "../images/inspiration-builder/sliders/desktop_1.webp";
import sliderDesktopImg2 from "../images/inspiration-builder/sliders/desktop_2.webp";
import sliderDesktopImg3 from "../images/inspiration-builder/sliders/desktop_3.webp";
import sliderDesktopImg4 from "../images/inspiration-builder/sliders/desktop_4.webp";
import sliderDesktopImg5 from "../images/inspiration-builder/sliders/desktop_5.webp";
import sliderDesktopImg6 from "../images/inspiration-builder/sliders/desktop_6.webp";
import sliderDesktopImg7 from "../images/inspiration-builder/sliders/desktop_7.webp";
import sliderDesktopImg8 from "../images/inspiration-builder/sliders/desktop_8.webp";
import sliderDesktopImg9 from "../images/inspiration-builder/sliders/desktop_9.webp";
import sliderDesktopImg10 from "../images/inspiration-builder/sliders/desktop_10.webp";
import sliderDesktopImg11 from "../images/inspiration-builder/sliders/desktop_11.webp";
import sliderDesktopImg12 from "../images/inspiration-builder/sliders/desktop_12.webp";
import sliderDesktopImg13 from "../images/inspiration-builder/sliders/desktop_13.webp";
import sliderDesktopImg14 from "../images/inspiration-builder/sliders/desktop_14.webp";
import sliderDesktopImg15 from "../images/inspiration-builder/sliders/desktop_15.webp";
import sliderDesktopImg16 from "../images/inspiration-builder/sliders/desktop_16.webp";
import sliderMobileImg1 from "../images/inspiration-builder/sliders/mobile_1.webp";
import sliderMobileImg2 from "../images/inspiration-builder/sliders/mobile_2.webp";
import sliderMobileImg3 from "../images/inspiration-builder/sliders/mobile_3.webp";
import sliderMobileImg4 from "../images/inspiration-builder/sliders/mobile_4.webp";
import sliderMobileImg5 from "../images/inspiration-builder/sliders/mobile_5.webp";
import sliderMobileImg6 from "../images/inspiration-builder/sliders/mobile_6.webp";
import sliderMobileImg7 from "../images/inspiration-builder/sliders/mobile_7.webp";
import sliderMobileImg8 from "../images/inspiration-builder/sliders/mobile_8.webp";
import sliderMobileImg9 from "../images/inspiration-builder/sliders/mobile_9.webp";
import sliderMobileImg10 from "../images/inspiration-builder/sliders/mobile_10.webp";
import sliderMobileImg11 from "../images/inspiration-builder/sliders/mobile_11.webp";
import sliderMobileImg12 from "../images/inspiration-builder/sliders/mobile_12.webp";
import sliderMobileImg13 from "../images/inspiration-builder/sliders/mobile_13.webp";
import sliderMobileImg14 from "../images/inspiration-builder/sliders/mobile_14.webp";
import sliderMobileImg15 from "../images/inspiration-builder/sliders/mobile_15.webp";
import sliderMobileImg16 from "../images/inspiration-builder/sliders/mobile_16.webp";
import { TfiAngleRight } from "react-icons/tfi";
import { navigate } from "gatsby";

import { BsDownload } from "react-icons/bs";

const desktopSlider = [
    {
        id: 1,
        imageDes: sliderDesktopImg1,
        imageMob: sliderMobileImg1,
    },
    {
        id: 2,
        imageDes: sliderDesktopImg2,
        imageMob: sliderMobileImg2,
    },
    {
        id: 3,
        imageDes: sliderDesktopImg3,
        imageMob: sliderMobileImg3,
    },
    {
        id: 4,
        imageDes: sliderDesktopImg4,
        imageMob: sliderMobileImg4,
    },
    {
        id: 5,
        imageDes: sliderDesktopImg5,
        imageMob: sliderMobileImg5,
    },
    {
        id: 6,
        imageDes: sliderDesktopImg6,
        imageMob: sliderMobileImg6,
    },
    {
        id: 7,
        imageDes: sliderDesktopImg7,
        imageMob: sliderMobileImg7,
    },
    {
        id: 8,
        imageDes: sliderDesktopImg8,
        imageMob: sliderMobileImg8,
    },
    {
        id: 9,
        imageDes: sliderDesktopImg9,
        imageMob: sliderMobileImg9,
    },
    {
        id: 10,
        imageDes: sliderDesktopImg10,
        imageMob: sliderMobileImg10,
    },
    {
        id: 11,
        imageDes: sliderDesktopImg11,
        imageMob: sliderMobileImg11,
    },
    {
        id: 12,
        imageDes: sliderDesktopImg12,
        imageMob: sliderMobileImg12,
    },
    {
        id: 13,
        imageDes: sliderDesktopImg13,
        imageMob: sliderMobileImg13,
    },
    {
        id: 14,
        imageDes: sliderDesktopImg14,
        imageMob: sliderMobileImg14,
    },
    {
        id: 15,
        imageDes: sliderDesktopImg15,
        imageMob: sliderMobileImg15,
    },
    {
        id: 16,
        imageDes: sliderDesktopImg16,
        imageMob: sliderMobileImg16,
    },
];
const quotes = [
    "Every moment matters",
    "Celebrate every day",
    "Enjoy the little things",
    "Live in the moment",
    "Make yourself a priority",
];

const InspirationBuilderPage = () => {
    // const deviceSizes = useDeviceSize();
    const [step, setStep] = React.useState(1);
    const [imgIndex, setImgIndex] = React.useState(0);
    const [selectedText, setSelectedText] = React.useState(quotes[0]);

    const settings = {
        arrow: true,
        dots: true,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        swipe: false,
        afterChange: (currSlide) => setImgIndex(currSlide),
    };
    const generateWallPath = (type) => {
        const imgName = selectedText.split(" ").join("-");
        const path = `/assets/wallpapers/${imgName}_${type}_${
            imgIndex + 1
        }.webp`;
        // console.log(path);
        return path;
    };

    const handleStepChange = (step) => {
        setStep(step);
        navigate("/inspiration-builder");
    };

    return (
        <HcpLayout
            pageClass="inspiration-builder-page orphan-page"
            jobCode="US-WAK-2300498/Dec 2023"
            isGhostPage="true"
        >
            <section className="cta-block">
                <div className="main-wrapper">
                    <section
                        className={`start ${step === 1 ? "current-step" : ""}`}
                    >
                        <div className="content-wrap">
                            <div className="centered-content">
                                <div className="page-content">
                                    <h1>What Inspires You?</h1>

                                    <div className="media">
                                        <img
                                            src={pageIcon}
                                            className="mobile-header-icon"
                                            alt="Page Icon"
                                        />
                                        <div className="text-content">
                                            <div>
                                                <p>
                                                    Create an inspiring
                                                    background for your phone or
                                                    computer. You'll select an
                                                    image and then a quote to go
                                                    with it.
                                                </p>
                                                <p className="only-desktop">
                                                    <strong>
                                                        Once you're happy with
                                                        your creation, download
                                                        it.
                                                    </strong>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="only-mobile">
                                            <strong>
                                                Once you're happy with your
                                                creation, download it.
                                            </strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slider-wrapper">
                            <div className="centered-content">
                                <div className="slider-block">
                                    <h2>Choose an Image</h2>

                                    <Slider
                                        {...settings}
                                        className="insprition-slider desktop"
                                    >
                                        {desktopSlider.map((item) => (
                                            <div
                                                className="insprition-slider__item"
                                                key={item.id}
                                            >
                                                <div className="insprition-slider__media">
                                                    <img
                                                        src={item.imageDes}
                                                        alt="Slider"
                                                        className="for-desktop"
                                                    />
                                                    <img
                                                        src={item.imageMob}
                                                        alt="Slider"
                                                        className="for-mobile"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                                <div className="select-section">
                                    <div className="form-wrapper">
                                        <div className="question-wrapper">
                                            <h2>Choose a Quote</h2>
                                            <div
                                                class="radio_set"
                                                onChange={(e) =>
                                                    setSelectedText(
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                {quotes.map((item, index) => (
                                                    <fieldset
                                                        class="radio"
                                                        key={item}
                                                    >
                                                        <label for={item}>
                                                            <input
                                                                type="radio"
                                                                id={item}
                                                                name="quote"
                                                                value={item}
                                                                checked={
                                                                    item ===
                                                                    selectedText
                                                                }
                                                            />
                                                            <p class="noselect">
                                                                <span class="radio_span"></span>
                                                                {item}
                                                            </p>
                                                        </label>
                                                    </fieldset>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-wrap text-center">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => handleStepChange(2)}
                                    >
                                        Next
                                        <TfiAngleRight />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section
                        className={`step-2 ${step === 2 ? "current-step" : ""}`}
                    >
                        <div className="review-block">
                            <div className="centered-content">
                                <div className="top-content">
                                    <h2 className="font-40">Review</h2>
                                    <p>
                                        Do you like your wallpaper? If you're
                                        not sure, you can{" "}
                                        <span
                                            onClick={() => setStep(1)}
                                            className="lnk-back"
                                            role="button"
                                            tabIndex={0}
                                            onKeyDown={(event) => {
                                                if (
                                                    event.key === "Enter" ||
                                                    event.key === " "
                                                ) {
                                                    event.preventDefault();
                                                    setStep(1);
                                                }
                                            }}
                                        >
                                            go back and change your selections
                                        </span>
                                        .
                                    </p>
                                    <p>
                                        When you're happy with your wallpaper,
                                        you can download it.
                                    </p>
                                </div>
                            </div>
                            <div className="frame-wrapper">
                                <div className="centered-content">
                                    <Row className="review-wrap">
                                        <Col lg={4} className="col-1">
                                            <div className="mobile-frame">
                                                <div className="displayed-media">
                                                    <img
                                                        src={generateWallPath(
                                                            "mobile"
                                                        )}
                                                        alt=""
                                                    />
                                                </div>
                                                {/* <h3 className="title"><span>{selectedText.split(' ')[0]}</span>{selectedText.slice(selectedText.indexOf(' '))}</h3> */}
                                            </div>
                                            <div className="btn-wrap ">
                                                <a
                                                    download
                                                    href={generateWallPath(
                                                        "mobile"
                                                    )}
                                                    className="btn btn-primary dwnload"
                                                >
                                                    Download
                                                    <BsDownload size="20" />
                                                </a>
                                            </div>
                                        </Col>
                                        <Col lg={8} className="col-2">
                                            <div className="desktop-frame">
                                                <div className="displayed-media">
                                                    <img
                                                        src={generateWallPath(
                                                            "desktop"
                                                        )}
                                                        alt=""
                                                    />
                                                </div>
                                                {/* <h3 className="title"><span>{selectedText.split(' ')[0]}</span>{selectedText.slice(selectedText.indexOf(' '))}</h3> */}
                                            </div>
                                            <div className="btn-wrap">
                                                <a
                                                    download
                                                    href={generateWallPath(
                                                        "desktop"
                                                    )}
                                                    className="btn btn-primary dwnload"
                                                >
                                                    Download
                                                    <BsDownload size="20" />
                                                </a>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </HcpLayout>
    );
};

export default InspirationBuilderPage;

export const Head = () => (
    <Seo
        title="Inspirational Wallpaper Builder | WAKIX® (pitolisant) tablets"
        description=""
        keywords="WAKIX support, WAKIX learn more, WAKIX news, WAKIX updates, WAKIX sign up, WAKIX registration, WAKIX program, WAKIX program sign up, WAKIX program registration, pitolisant support, pitolisant learn more, pitolisant news, pitolisant updates"
    />
);
